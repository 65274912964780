$(function () {
  $('[data-nav-trigger]').on('click', function () {
    $(this).toggleClass('active');
    $('[data-nav]').fadeToggle(200);
  });

  $('[data-toggle]').on('click', function () {
    const target = $(this).data('toggle');
    $('[data-toggle-target=' + target + ']').slideToggle(200);
    return false;
  });
});
